<template>
  <div v-if="team.data">
    <v-row justify="center" class="pt-5">
      <v-col cols="12">
        <v-row>
          <v-col cols="auto" align-self="center">
            <v-btn icon to="/teams">
              <v-icon large>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto" class="text-center" align-self="center">
            <v-img width="50px" :src="team.data.verein.logo" />
          </v-col>
          <v-col align-self="center">
            <h3 class="white--text font-weight-light">
              {{ team.data.verein.name }}
            </h3>
            <h2 class="white--text font-weight-bold">{{ team.data.name }}</h2>
          </v-col>
          <v-col cols="auto" align-self="center">
            <v-btn
              :fab="$vuetify.breakpoint.smAndDown"
              :text="$vuetify.breakpoint.mdAndUp"
              @click="newmember = true"
            >
              <v-icon>mdi-account-plus</v-icon>
              <span v-if="$vuetify.breakpoint.mdAndUp" class="ml-2">
                Mitglied hinzufügen
              </span>
            </v-btn>
          </v-col>
          <v-col cols="12" class="text-center my-5" align-self="center">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" rounded dark v-bind="attrs" v-on="on">
                  Optionen
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="user.data.rolle == 'Vorstand'"
                  :to="`/team/${team.id}/bearbeiten`"
                >
                  <v-list-item-title>
                    <v-icon class="mr-2">mdi-pencil</v-icon>
                    TEAM BEARBEITEN
                  </v-list-item-title>
                </v-list-item>
                <v-list-item :to="`/team/${team.id}/import`">
                  <v-list-item-title>
                    <v-icon class="mr-2">mdi-upload</v-icon>
                    TEAM-MITGLIEDER IMPORTIEREN
                  </v-list-item-title>
                </v-list-item>

                <v-list-item link v-if="user.data.rolle == 'Vorstand'">
                  <v-list-item-title>
                    <download-csv
                      :data="spieler_export"
                      delimiter=";"
                      :name="`team_export_${team.data.verein.name}_${team.data.name}.csv`"
                    >
                      <v-icon class="mr-2">mdi-download</v-icon>
                      TEAM-MITGLIEDER EXPORTIEREN
                    </download-csv>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      class="pa-0"
      justify="center"
      :style="`max-height: ${
        user.data.rolle == 'Vorstand' && $vuetify.breakpoint.smAndDown
          ? '60vh'
          : $vuetify.breakpoint.smAndDown
          ? '70vh'
          : user.data.rolle == 'Vorstand'
          ? '75vh'
          : '80vh'
      }; overflow-x: hidden; overflow-y: scroll;`"
    >
      <v-col cols="12" class="text-right">
        <v-row style="min-height: 80px;">
          <v-col cols="6" class="text-left" align-self="center">
            <v-checkbox
              on-icon="mdi-checkbox-marked-outline"
              :color="select_active ? template.colors.success : undefined"
              v-model="select_active"
              label="AUSWÄHLEN
              "
            >
              <template slot="label" v-if="select_active">
                <span class="font-weight-bold mr-2">{{ selected.length }}</span>
                AUSGEWÄHLT
              </template>
            </v-checkbox>
          </v-col>

          <v-col
            cols="6"
            class="text-right"
            v-if="!select_active"
            align-self="center"
          >
            <span class="font-weight-light">SORTIERUNG:</span>
            <v-btn text @click="change_sort()">
              <v-icon class="mr-1">mdi-sort-ascending</v-icon>
              {{ sort == 'vorname' ? 'VORNAME' : 'Nachname' }}
            </v-btn>
          </v-col>
          <v-col cols="6" v-if="select_active" align-self="center">
            <v-btn
              text
              rounded
              :disabled="selected.length == 0"
              @click="start_moving = true"
            >
              VERSCHIEBEN
            </v-btn>
            <v-btn
              text
              rounded
              :disabled="selected.length == 0"
              @click="start_deleting = true"
            >
              LÖSCHEN
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="spieler.length == 0">
        <v-card
          rounded="xl"
          :color="template.colors.blocks"
          :light="!template.isDark(template.colors.blocks)"
          :dark="template.isDark(template.colors.blocks)"
        >
          <v-row justify="center">
            <v-col class="text-center" cols="10">
              <h3>Diesem Team wurden noch keine Mitglieder zugeordnet</h3>
              <v-btn
                rounded
                class="my-3"
                :style="`color: ${template.colors.inline_primary_text};`"
                :color="template.colors.primary"
                @click="newmember = true"
              >
                Mitglied hinzufügen
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="py-0"
        v-for="(t, index) in spieler"
        :key="index"
      >
        <cardbutton
          :title="
            t.vorname +
            ' ' +
            t.name +
            (t.rolle ? (t.rolle != 'Sportler' ? ' (' + t.rolle + ')' : '') : '')
          "
          :icon="
            select_active && selected_ids.includes(t.id)
              ? 'mdi-check-bold'
              : 'mdi-account'
          "
          :textcolor="
            select_active && selected_ids.includes(t.id)
              ? template.colors.success_text
              : template.colors.block_text
          "
          :bgcolor="
            select_active && selected_ids.includes(t.id)
              ? template.colors.success
              : template.colors.blocks
          "
          upper
          :chev="!select_active"
          :to="select_active ? undefined : `/team/${team.id}/mitglied/${t.id}`"
          @click="select_active ? select(t) : undefined"
        />
      </v-col>
    </v-row>
    <CreateMember v-if="newmember" v-on:closing="newmember = false" />
    <v-dialog
      v-model="start_moving"
      :content-class="$vuetify.breakpoint.mdAndUp ? 'rounded-xl' : undefined"
      :max-width="$vuetify.breakpoint.mdAndUp ? '700px' : undefined"
      :fullscreen="!$vuetify.breakpoint.mdAndUp"
    >
      <v-card>
        <v-toolbar :color="template.colors.primary">
          <v-toolbar-title>MITGLIEDER VERSCHIEBEN</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              start_moving = false
              move_step = 1
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row class="pa-4" justify="center" v-if="move_step == 1">
          <v-col cols="10" class="text-center">
            <span class="font-weight-bold">
              Bitte wähle ein Team aus, in das du
              {{
                selected.length > 1
                  ? 'die ' + selected.length + ' ausgewählten Team-Mitglieder'
                  : 'das ausgewählte Team-Mitglied'
              }}
              verschieben möchtest:
            </span>
          </v-col>
          <v-col cols="12" md="4" v-for="(team, index) in teams" :key="index">
            <v-card
              class="rounded-xl pa-2"
              :color="template.colors.blocks"
              :light="!template.isDark(template.colors.blocks)"
              :dark="template.isDark(template.colors.blocks)"
              link
              @click="
                move_team = team
                move_step = 2
              "
            >
              <v-row>
                <v-col align-self="center">
                  <h3>{{ team.name }}</h3>
                </v-col>
                <v-col cols="auto" align-self="center">
                  <v-icon large>mdi-chevron-right</v-icon>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="pa-4" justify="center" v-if="move_step == 2">
          <v-col cols="10" align-self="center" class="text-center">
            <span>
              {{
                selected.length > 1
                  ? 'Die ausgewählten Team-Mitglieder:'
                  : 'Das ausgewählte Team-Mitglied'
              }}
              <span
                class="font-weight-bold"
                v-for="(spieler, index) in selected"
                :key="index"
              >
                {{ spieler.vorname }}
                {{
                  (
                  selected.length > (index + 2)
                    ? spieler.name+', '
                    : selected.length == (index +2)
                    ? spieler.name+' und '
                    : spieler.name
                )
                }}
              </span>
              {{ selected.length > 1 ? 'werden' : 'wird' }} in das Team
              <span class="font-weight-bold">{{ move_team.name }}</span>
              verschoben.
            </span>
          </v-col>
          <v-col cols="10" align-self="center" class="text-center">
            <v-btn
              :color="template.colors.primary"
              large
              rounded
              @click="move()"
              :loading="moving"
            >
              VERSCHIEBEN STARTEN
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="start_deleting"
      :content-class="$vuetify.breakpoint.mdAndUp ? 'rounded-xl' : undefined"
      :max-width="$vuetify.breakpoint.mdAndUp ? '700px' : undefined"
      :fullscreen="!$vuetify.breakpoint.mdAndUp"
    >
      <v-card>
        <v-toolbar :color="template.colors.primary">
          <v-toolbar-title>MITGLIEDER LÖSCHEN</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="start_deleting = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row class="pa-4" justify="center">
          <v-col cols="10" align-self="center" class="text-center">
            <span>
              {{
                selected.length > 1
                  ? 'Die ausgewählten Team-Mitglieder'
                  : 'Das ausgewählte Team-Mitglied'
              }}
              <span
                class="font-weight-bold"
                v-for="(spieler, index) in selected"
                :key="index"
              >
                {{ spieler.vorname }}
                {{
                  (
                  selected.length > (index + 2)
                    ? spieler.name+', '
                    : selected.length == (index +2)
                    ? spieler.name+' und '
                    : spieler.name
                )
                }}
              </span>
              {{ selected.length > 1 ? 'werden' : 'wird' }}
              <span class="font-weight-bold">UNWIDERRUFLICH</span>
              aus dem Team gelöscht.
              <br />
              <br />
              <small>
                Bereits angelegte Anwesenheitsaufzeichnungen, die noch nicht
                älter als 4 Wochen sind,
                {{
                  selected.length > 1 ? 'dieser Mitglieder' : 'dieses Mitglieds'
                }}
                bleiben erhalten.
              </small>
            </span>
          </v-col>
          <v-col cols="10" align-self="center" class="text-center">
            <v-btn
              :color="template.colors.primary"
              large
              rounded
              @click="del()"
              :loading="deleting"
            >
              UNWIDERRUFLICH LÖSCHEN
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import cardbutton from '../../../elements/cards/card-button'
//import firebase from '../../../firebaseConfig'
import store from '../../../store'
import CreateMember from './createMember'

export default {
  name: 'Team',
  data() {
    return {
      spieler: [],
      spieler_export: [],
      selected: [],
      teams: [],
      selected_ids: [],
      start_moving: false,
      move_step: 1,
      move_team: '',
      moving: false,
      deleting: false,
      start_deleting: false,
      select_active: false,
      newmember: '',
      anz_teilnehmer: 0,
      zuschauerinfo: false,
      veranstaltungedit: false,
      sort: 'vorname',
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      team: 'team',
      template: 'template',
    }),
  },
  mounted() {
    if (this.$route.params.team) {
      if (this.team.id != this.$route.params.team) {
        store.dispatch('setTeam', this.$route.params.team)
      }
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .orderBy('name')
        .get()
        .then(
          (querySnap) => {
            this.teams = []
            querySnap.forEach((doc) => {
              if (doc.id != this.$route.params.team) {
                this.teams.push(doc.data())
                var index = this.teams.length - 1
                this.teams[index].id = doc.id
              }
            })
          },
          (error) => {
            console.log(error)
          },
        )
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .doc(this.$route.params.team)
        .collection('Mitglieder')
        .orderBy('vorname')
        .onSnapshot(
          (querySnap) => {
            this.spieler = []
            this.spieler_export = []

            var voll_geimpft = new Date()
            voll_geimpft.setDate(voll_geimpft.getDate() - 14)
            voll_geimpft =
              voll_geimpft.getFullYear() +
              '-' +
              (voll_geimpft.getMonth() + 1).toString().padStart(2, '0') +
              '-' +
              voll_geimpft.getDate().toString().padStart(2, '0')

            var voll_genesen = new Date()
            voll_genesen.setDate(voll_genesen.getDate() - 180)
            voll_genesen =
              voll_genesen.getFullYear() +
              '-' +
              (voll_genesen.getMonth() + 1).toString().padStart(2, '0') +
              '-' +
              voll_genesen.getDate().toString().padStart(2, '0')

            querySnap.forEach((doc) => {
              var p = doc.data()
              this.spieler.push(p)
              var index = this.spieler.length - 1
              this.spieler[index].id = doc.id
              var immun = p.geimpft
                ? p.geboostert
                  ? 'Vollständig geimpft'
                  : p.impfdatum && p.impfdatum < voll_geimpft
                  ? 'Vollständig geimpft'
                  : ''
                : p.genesen && p.genesen.datum > voll_genesen
                ? 'Genesen'
                : ''
              this.spieler_export.push({
                Nachname: p.name,
                Vorname: p.vorname,
                Straße: p.adresse,
                PLZ: p.plz,
                Ort: p.ort,
                Mobil: p.telefon,
                Immunisierung: immun,
              })
            })
          },
          (error) => {
            console.log(error)
          },
        )
    } else {
      store.dispatch('closeTeam')
    }
  },
  components: {
    CreateMember,
    cardbutton,
  },
  methods: {
    select(spieler) {
      if (this.selected_ids.includes(spieler.id)) {
        var members = this.selected
        this.selected_ids = []
        this.selected = []
        members.forEach((member) => {
          if (member.id != spieler.id) {
            this.selected_ids.push(member.id)
            this.selected.push(member)
          }
        })
      } else {
        this.selected_ids.push(spieler.id)
        this.selected.push(spieler)
      }
    },
    async move() {
      if (this.selected.length > 0 && this.move_team) {
        this.moving = true
        var index = 0
        this.selected.forEach(async (member) => {
          await firebase
            .firestore()
            .collection('User')
            .doc(this.user.data.uid)
            .collection('Team')
            .doc(this.move_team.id)
            .collection('Mitglieder')
            .doc(member.id)
            .set(member)
            .then(async () => {
              await firebase
                .firestore()
                .collection('User')
                .doc(this.user.data.uid)
                .collection('Team')
                .doc(this.$route.params.team)
                .collection('Mitglieder')
                .doc(member.id)
                .delete()
                .then(() => {
                  if (index + 1 == this.selected.length) {
                    this.moving = false
                    this.start_moving = false
                    this.selected = []
                    this.select_active = false
                  } else {
                    index++
                  }
                })
            })
        })
      }
    },
    async del() {
      if (this.selected.length > 0) {
        this.deleting = true
        var index = 0
        this.selected.forEach(async (member) => {
          await firebase
            .firestore()
            .collection('User')
            .doc(this.user.data.uid)
            .collection('Team')
            .doc(this.$route.params.team)
            .collection('Mitglieder')
            .doc(member.id)
            .delete()
            .then(() => {
              if (index + 1 == this.selected.length) {
                this.deleting = false
                this.start_deleting = false
                this.selected = []
                this.select_active = false
              } else {
                index++
              }
            })
        })
      }
    },
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    parseDate1(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.'
    },
    change_sort() {
      if (this.sort == 'vorname') {
        this.sort = 'name'
      } else {
        this.sort = 'vorname'
      }
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .doc(this.$route.params.team)
        .collection('Mitglieder')
        .orderBy(this.sort)
        .onSnapshot((querySnap) => {
          this.spieler = []
          querySnap.forEach((doc) => {
            this.spieler.push(doc.data())
            var index = this.spieler.length - 1
            this.spieler[index].id = doc.id
          })
        })
    },
  },
}
</script>
>
